require('./bootstrap');

$(function (){
    if(window.location.href !== public_path + "login"){
        $.ajax({
            data:'',
            url:public_path + "ping",
            type:'GET',
            success:function (res){
                if(res == 0){
                    window.location = public_path + "login";
                }
            }
        });
    }
})


